<template>
  <div class="co-flex-col">
    <v-card
      class="rounded overflow-y-auto overflow-x-hidden"
      flat
      tile
      color="white"
      height="640"
    >
      <div class="co-flex-col co-justify-between co-h-full py-6 px-10">
        <template v-if="items.length">
          <div class="co-flex-col">
            <div
              v-for="(item, index) in items"
              :key="index"
              class="co-flex-col"
            >
              <div class="co-flex-row co-items-center">
                <v-avatar
                  size="64"
                  style="cursor: pointer;"
                  @click="toHomepage(index, item)"
                >
                  <v-img
                    :src="item.content.avatar"
                    cover
                  />
                </v-avatar>
                <div class="co-flex-row co-justify-between co-items-center co-flex-grow ml-4">
                  <div class="co-flex-col">
                    <div class="co-flex-row co-items-center">
                      <span
                        class="primary--text"
                        style="cursor: pointer;"
                        @click="toHomepage(index, item)"
                      >
                        {{ item.content.username }}
                      </span>
                      <span class="co-text-gray-500 ml-4">
                        {{ hint.label }}
                        <span class="co-text-gray-700 ml-2">{{ `“${item.content.commentContent}”` }}</span>
                      </span>
                    </div>
                    <div class="co-flex-row co-items-center mt-2">
                      <el-popover
                        :ref="`${popReply.name}_${index}`"
                        :placement="popReply.placement"
                        :width="popReply.width"
                        trigger="click"
                      >
                        <el-input
                          v-model="popReply.content"
                          :placeholder="popReply.placeholder"
                        >
                          <el-button
                            slot="append"
                            @click="submitReply(index, item)"
                          >
                            {{ popReply.action }}
                          </el-button>
                        </el-input>
                        <div
                          slot="reference"
                          class="co-flex-row co-items-center mr-6"
                          style="cursor: pointer;"
                        >
                          <v-icon
                            size="medium"
                            color="blue-grey darken-2"
                          >
                            {{ btnReply.icon }}
                          </v-icon>
                          <span class="co-text-sm blue-grey--text darken-2 ml-2">{{ btnReply.text }}</span>
                        </div>
                      </el-popover>

                      <span class="co-text-xs co-text-gray-500">
                        {{ item.content.hintTime }}
                        <span class="ml-2">{{ `( ${item.content.shortTime} )` }}</span>
                      </span>
                    </div>
                  </div>

                  <v-avatar
                    v-if="item.content.contentCover"
                    size="64"
                    class="rounded"
                    style="cursor: pointer;"
                    @click="toContent(index, item)"
                  >
                    <v-img
                      :src="item.content.contentCover"
                      cover
                    />
                  </v-avatar>
                </div>
              </div>
              <div class="co-w-full co-h2 co-border-t co-border-gray-300 my-4" />
            </div>
          </div>
        </template>
        <template v-else>
          <span class="co-text-sm co-text-gray-500">
            {{ hint.empty }}
          </span>
        </template>
      </div>
    </v-card>

    <div
      v-if="navPage.length"
      class="d-flex flex-row my-4"
    >
      <v-pagination
        v-model="navPage.current"
        :length="navPage.length"
        :total-visible="navPage.totalVisible"
        @input="changePagination($event, catCur.name)"
      />
    </div>
    <dialog-alert ref="dlgAlert" />
  </div>
</template>

<script>
  import api from '@/api/co.api'
  import web from '@/api/web/co.web'
  import user from '@/api/co.user'

  export default {
    name: 'SectionMessageComments',
    components: {
      DialogAlert: () => import('@/pages/sections/comm/DialogAlert.vue')
    },
    props: {
      items: {
        type: Array,
        default: () => {
          return []
        }
      },
      pagination: {
        type: Object,
        default: () => {
          return {
            current: 1,
            length: 1,
            totalVisible: 7
          }
        }
      }
    },
    data () {
      return {
        userInfo: {},
        hint: {
          label: '评论我',
          empty: '目前没有评论消息'
        },
        navPage: {
          current: 1,
          length: 1,
          totalVisible: 7
        },
        btnReply: {
          icon: 'mdi-comment-processing-outline',
          text: '回复'
        },
        popReply: {
          name: 'popReply',
          visible: false,
          content: '',
          placement: 'bottom',
          width: '400',
          placeholder: '输入回复内容',
          action: '发送'
        },
      }
    },
    watch: {
      pagination: {
        handler (val) {
          this.navPage = Object.assign({}, val)
        },
        immediate: true
      }
    },
    created () {
      this.userInfo = user.info.get()
    },
    methods: {
      showAlert (type, message) {
        const dlg = this.$refs.dlgAlert
        if (dlg) {
          dlg.show({
            type,
            message
          })
        }
      },
      submitReply (index, item) {
        console.log({ index, item })

        const cnt = item.content
        const name = `${this.popReply.name}_${index}`
        const frms = this.$refs[name] || []
        const frm = frms[0]

        const me = this
        const data = {
          content: this.popReply.content,
          commentId: cnt.commentId,
          username: this.userInfo.username,
          avatar: this.userInfo.avatar,
          mobile: this.userInfo.mobile
        }

        const executing = function () {}
        const executed = function (res) {
          console.log({ res })
          if (res.status) {
            me.showAlert('success', '回复成功')
          } else {
            me.showAlert('error', '回复失败')
          }

          if (frm) {
            frm.doClose()
          }
        }
        web.reply.create({
          data,
          executing,
          executed
        })
      },
      toHomepage (index, item) {
        console.log({ item })
        const userId = item.content.createBy
        if (!userId) {
          return
        }

        if (userId === this.userInfo.userId) {
          const params = {
            ...this.userInfo
          }
          api.page.navigate(this, 'my', params)
        } else {
          api.page.navigateBy(this, 'homepage', {
            userId
          })
        }
      },
      toContent (index, item) {
        const params = {}
        const content = item.content || {}
        params.contentId = content.contentId
        params.title = content.title
        params.type = item.type

        api.page.navigate(this, 'hotDetail', params)
      }
    }
  }
</script>
